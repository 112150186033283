import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const UserInfoInput = ({ control, errors }) => {
  const { t } = useTranslation();
  return (
    <>
      <h3>{t('main.userData')}</h3>
      <div className='user-data-input-container'>
        <Controller
          name='first_name'
          control={control}
          render={({ field }) => (
            <div className='input-container'>
              <input
                {...field}
                required
                type='text'
                className='user-data-input'
                placeholder={t('reviews.namePlaceholder')}
              />
              <label>{t('reviews.namePlaceholder')}</label>
            </div>
          )}
        />
        <Controller
          name='tg_user_name'
          control={control}
          render={({ field }) => (
            <div className='input-container'>
              <input
                {...field}
                required
                type='text'
                className='user-data-input'
                placeholder={t('reviews.tgUserName')}
              />
              <label>{t('reviews.tgUserName')}</label>
            </div>
          )}
        />
        <Controller
          name='email'
          control={control}
          render={({ field }) => (
            <div className='input-container'>
              <input
                {...field}
                required
                type='email'
                className='user-data-input'
                placeholder={t('reviews.emailPlaceholder')}
              />
              <label>{t('reviews.emailPlaceholder')}</label>
            </div>
          )}
        />
        <Controller
          name='agreeToTerms'
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <label className='custom-label'>
              <input {...field} type='checkbox' id='agreeToTerms' />
              <span className='custom-checkbox'></span>
              <NavLink to='/rules' target="_blank" rel="noopener noreferrer">{t('main.i_agree_site_rule')}</NavLink>
            </label>
          )}
        />
        {errors && errors.agreeToTerms && (
          <span className='error-message'>{t('main.fieldRequired')}</span>
        )}
        <Controller
          name='agreeToKYC'
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <label className='custom-label'>
              <input {...field} type='checkbox' id='agreeToKYC' />
              <span className='custom-checkbox'></span>
              <NavLink to='/kyc' target="_blank" rel="noopener noreferrer">{t('main.i_agree_kyc')}</NavLink>
            </label>
          )}
        />
        {errors && errors.agreeToKYC && (
          <span className='error-message'>{t('main.fieldRequired')}</span>
        )}
      </div>
    </>
  );
};

export default UserInfoInput;
