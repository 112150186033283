import './footer.css';
import { NavItem } from '../navbar/Navbar';
import { useTranslation } from 'react-i18next';
import telegramImg from './../../img/telegram.svg';
import watsAppImg from './../../img/watsApp.svg';
import whiteTelegramImg from './../../img/whiteTelegram.svg';

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <footer className='footer-section'>
      <div className='container'>
        <div className='footer-cta pt-5 pb-5'>
          <div className='row'>
            <div className='col-xl-4 col-md-4 mb-30'>
              <div className='single-cta'>
                <i className='fas fa-map-marker-alt'></i>
                <div className='cta-text'>
                  <h3>{t('footer.address')}</h3>
                  <address>
                    <div>{t('contacts.addressUfa')}</div>
                  </address>
                  <address>
                    <div>{t('contacts.addressIstanbul')}</div>
                  </address>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-md-4 mb-30'>
              <div className='single-cta'>
                <i className='fas fa-phone'></i>
                <div className='cta-text'>
                  <h4>{t('footer.callUs')}</h4>
                  <span>
                    <div>
                      {t('contacts.contactRussia')}
                      <a href='tel:+79870275033'>+7(987)0275033</a>
                    </div>
                  </span>
                  <span>
                    <div>
                      {t('contacts.contactTurkey')}{' '}
                      <a href='tel:+905319711791'>+90(531)9711791</a>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-md-4 mb-30'>
              <div className='single-cta'>
                <i className='far fa-envelope-open'></i>
                <div className='cta-text'>
                  <h4>{t('footer.mail')}</h4>
                  <a href='mailto:dimmarexchange@gmail.com'>
                    dimmarexchange@gmail.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-content pt-5 pb-5'>
          <div className='row'>
            <div className='social-items-wrapper'>
              <h3>{t('footer.contactUs')}</h3>
              <div className='social-items-container'>
                <div className='social-items'>
                  <a
                    href='tg://resolve?domain=DIMMAR_EX'
                    target='_blank'
                    rel='noreferrer'
                    className='social-button'
                  >
                    <img
                      src={telegramImg}
                      alt='Telegram'
                      width='50'
                      height='50'
                    />
                    Telegram
                  </a>
                  <a
                    href='https://wa.me/905319711791'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='social-button'
                  >
                    <img
                      src={watsAppImg}
                      alt='Telegram'
                      width='50'
                      height='50'
                    />
                    WhatsApp
                  </a>
                </div>
                <div className='social-items rigte-align'>
                  <a
                    href='tg://resolve?domain=Dimmar_exchange_Turkey'
                    target='_blank'
                    rel='noreferrer'
                    className='social-item'
                  >
                    <img
                      src={whiteTelegramImg}
                      alt='Telegram'
                      width='50'
                      height='50'
                    />
                    {t('contacts.ourTelegramChannel')}
                  </a>

                  <a
                    href='tg://resolve?domain=DimmarExBot'
                    target='_blank'
                    rel='noreferrer'
                    className='social-item'
                  >
                    <img
                      src={whiteTelegramImg}
                      alt='Telegram'
                      width='50'
                      height='50'
                    />
                    {t('contacts.ourTelegramBot')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='copyright-area'>
        <div className='container'>
          <div className='footer-menu'>
            <ul>
              <NavItem to='/'>{t('navbar.home')}</NavItem>

              <NavItem to='/kyc'>{t('navbar.kyc')}</NavItem>

              <NavItem to='/reviews'>{t('navbar.reviews')}</NavItem>

              <NavItem to='/contacts'>{t('navbar.contacts')}</NavItem>

              <NavItem to='/rules'>{t('navbar.rules')}</NavItem>

              <NavItem to='/partnership'>{t('navbar.partnership')}</NavItem>

              <NavItem to='/site-map'>{t('navbar.siteMap')}</NavItem>

              {i18n.language === 'ru' && (
                <NavItem to='/learning-materials'>
                  {t('navbar.learningMaterials')}
                </NavItem>
              )}

              <NavItem to='/politica'>{t('navbar.privacyPolicy')}</NavItem>
            </ul>
          </div>
        </div>
        <div className='container'>
          <div className='copyright-text'>
            <p
              dangerouslySetInnerHTML={{
                __html: t('footer.companyInfo'),
              }}
            ></p>
          </div>
        </div>
        <div className='container'>
          <div className='copyright-text'>
            <p>
              {t('footer.copyright')}&copy;{t('footer.definde')}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
