import { useState, useEffect } from 'react';
import { fetchCityOfficeInfo } from '../../api/get';
import './../../styles/officeInfo.css';
import locationImg from './../../img/location-mark.svg';
import contactIcon from './../../img/contactsIcon.svg';
import timeIcon from './../../img/timeIcon.svg';
import officeServiceIcon from './../../img/officeServiceIcon.svg';
import telegramImg from './../../img/telegram.svg';
import telegrammQrCode from './../../img/telegrammQrCode.webp';

function formatInfo(data) {
  return data.split(', ').map((item, index) => {
    const [key, value] = item.split(': ');

    if (key === 'Тел.') {
      return (
        <div key={index}>
          <span>
            <b>{key}: </b>
          </span>
          {/* Создаем телефонную ссылку для значения */}
          <span>
            <a href={`tel:${value.replace(/[^\d+]/g, '')}`}>{value}</a>
          </span>
        </div>
      );
    } else {
      return (
        <div key={index}>
          <span>
            <b>{key}: </b>
          </span>
          <span>{value}</span>
        </div>
      );
    }
  });
}

const CityOfficeInfo = ({ cityName }) => {
  const [citiOfficeInfo, setCityOfficeInfo] = useState(null);

  const getitiOfficeInfo = async () => {
    const response = await fetchCityOfficeInfo(cityName);
    if (response.success) {
      setCityOfficeInfo(response.data);
    }
  };

  useEffect(() => {
    getitiOfficeInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {citiOfficeInfo && (
        <div className='office-info-card'>
          <div className='office-details-wrapper'>
            <div>
              <img
                src={telegrammQrCode}
                alt='telegramm QrCode'
                width='200'
                height='auto'
              />
            </div>
            <div className='office-details'>
              <div className='office-address'>
                <div className='office-title'>
                  <img
                    src={locationImg}
                    alt='Location'
                    width='28'
                    height='28'
                  />
                  <span className='of-info'>Адрес офиса:</span>
                </div>
                <div>{citiOfficeInfo.address}</div>
                <div>
                  <b>Важно!</b> Обмен строго по предварительной записи! И
                  согласовании курса обмена!
                </div>
              </div>
              <div className='office-contacts'>
                <div className='office-title'>
                  <div className='office-icon-phone'>
                    <img
                      src={contactIcon}
                      alt='Contact'
                      width='28'
                      height='28'
                    />
                  </div>
                  <span className='of-info'>Контакты:</span>
                </div>
                <div>Для консультации и записи на обмен:</div>
                <div>{formatInfo(citiOfficeInfo.contacts)}</div>
                <div key='telegram'>
                  <a
                    href='tg://resolve?domain=DIMMAR_EX'
                    target='_blank'
                    rel='noreferrer'
                    className='social-button social-button-small'
                  >
                    <img
                      src={telegramImg}
                      alt='Telegram'
                      width='15'
                      height='15'
                    />
                    Telegram
                  </a>
                </div>
              </div>
              <div className='office-hours'>
                <div className='office-title'>
                  <img src={timeIcon} alt='Time' width='28' height='28' />
                  <span className='of-info'>Режим работы:</span>
                </div>
                <div>{formatInfo(citiOfficeInfo.hours_of_operation)}</div>
              </div>
              <div className='office-services'>
                <div className='office-title'>
                  <div className='office-icon-clock'>
                    <img
                      src={officeServiceIcon}
                      alt='Service'
                      width='28'
                      height='28'
                    />
                  </div>
                  <span className='of-info'>Услуги офиса:</span>
                </div>
                <div>
                  <b>Покупка, продажа, обмен Биткоина</b>
                </div>
                <div>
                  <b>Покупка, продажа, обмен USDT</b>
                  <div>(Сети TRC20, ERC20, BEP20)</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CityOfficeInfo;
