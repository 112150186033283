import { useTranslation } from 'react-i18next';
import whiteTelegramImg from './../../img/whiteTelegram.svg';

const FixiedTgBtn = () => {
  const { t } = useTranslation();

  return (
      <a
        href='tg://resolve?domain=DIMMAR_EX'
        target='_blank'
        rel='noopener noreferrer'
        className='fixied-tg-btn'
      >
        <img src={whiteTelegramImg} alt="Telegram" width={15} height='auto'/>
        {t('companyInfo.firstBlockBtn')}
      </a>
  );
};

export default FixiedTgBtn;
