import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/global.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import AppRoutes from './routes';
import { Context } from './index';
import { useEffect, useContext } from 'react';
import FixiedTgBtn from './components/elements/FixiedTgBtn';
import ScrollToTop from './components/hooks/ScrollToTop';
import { observer } from 'mobx-react-lite';
import i18next from 'i18next';

function App() {
  const { t } = useTranslation();
  const { store } = useContext(Context);

  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      store.checkAuth();
    }
  }, [store]);

  useEffect(() => {
    const updateLanguage = () => {
      const currentLanguage = i18next.language || 'en';
      document.documentElement.lang = currentLanguage;
    };
    updateLanguage();
    i18next.on('languageChanged', updateLanguage);
    return () => {
      i18next.off('languageChanged', updateLanguage);
    };
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
      <ScrollToTop />

        <Helmet>
          <title>{t('SEO.homePageTitle')}</title>
          <meta name='description' content={t('SEO.homePageDescription')} />
        </Helmet>
        <Navbar />
        <AppRoutes />
        <Footer />
        <FixiedTgBtn />
      </Router>
    </I18nextProvider>
  );
}

export default observer(App);
