import './styles/advantages.css';
import block1Img from '../../img/advantageIcons/block1.svg';
import block2Img from '../../img/advantageIcons/block2.svg';
import block3Img from '../../img/advantageIcons/block3.svg';
import block4Img from '../../img/advantageIcons/block4.svg';
import block5Img from '../../img/advantageIcons/block5.svg';
import { useTranslation } from 'react-i18next';
import { fillTemplate } from '../utils/formatArticleTitle';
import { useEffect } from 'react';
import { replacePlaceholderWithLink } from '../../utils/tools';
// import TgBotInfo from './TgBotInfo';

const AdvantageBlock = ({ imgSrc, title, desc }) => (
  <div className='adv-content-block'>
    <img src={imgSrc} alt={title} width={32} height={32} />
    <h3
      className='font-regular-20'
      dangerouslySetInnerHTML={{ __html: title }}
    ></h3>
    <p dangerouslySetInnerHTML={{ __html: desc }}></p>
  </div>
);

const FirstAdvantageBlock = ({ handleScrollToExchange, text }) => {
  const { t } = useTranslation();

  useEffect(() => {
    replacePlaceholderWithLink(
      'exchangeLinkPlaceholder',
      handleScrollToExchange,
      '#exchange-form-id',
      'exchange-link',
    );
  }, [handleScrollToExchange]);

  return (
    <div className='advantages-container'>
      <div className='advantages-container__row'>
        <div className='content-block'>
          <p
            dangerouslySetInnerHTML={{
              __html: text.firstText,
            }}
          ></p>
        </div>
        <div className='content-block'>
          <p
            dangerouslySetInnerHTML={{
              __html: text.secondText,
            }}
          ></p>
        </div>
      </div>
      <div className='button-container'>
        <a
          href='tg://resolve?domain=DIMMAR_EX'
          target='_blank'
          rel='noopener noreferrer'
          className='telegram-button'
        >
          {t('main.telegaBot')}
        </a>
        <a
          href='#exchange-form-id'
          className='exchange-button'
          onClick={handleScrollToExchange}
        >
          {t('main.get_change')}
        </a>
      </div>
    </div>
  );
};

const SecondAdvantageBlock = ({ blocks }) => {
  return (
    <div className='advantages-container b-white'>
      <div className='advantages-container__row'>
        <div>
          <AdvantageBlock
            imgSrc={block1Img}
            title={blocks.block1.title}
            desc={blocks.block1.desc}
          />
          <AdvantageBlock
            imgSrc={block3Img}
            title={blocks.block3.title}
            desc={blocks.block3.desc}
          />
        </div>
        <div>
          <AdvantageBlock
            imgSrc={block2Img}
            title={blocks.block2.title}
            desc={blocks.block2.desc}
          />
          <AdvantageBlock
            imgSrc={block4Img}
            title={blocks.block4.title}
            desc={blocks.block4.desc}
          />
          <AdvantageBlock
            imgSrc={block5Img}
            title={blocks.block5.title}
            desc={blocks.block5.desc}
          />
        </div>
      </div>
      <div className='font-regular-20 font-black '>{blocks.bottomText1}</div>
      <div className='font-regular-20'>{blocks.bottomText2}</div>
    </div>
  );
};

const ThirdBlock = ({ blocks }) => {
  return (
    <div className='bot-info-transfer'>
      {Object.entries(blocks).map(([key, value], index) => (
        <div key={key} className='bot-info-transfer-item'>
          <div className='bot-info-number-wrapper'>
            <div className='bot-info-number'>{index + 1}</div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: value }}></div>
        </div>
      ))}
    </div>
  );
};

const Advantages = ({ handleScrollToExchange }) => {
  const { t } = useTranslation();
  const firstBlockText = {
    firstText: t('advantage.description1'),
    secondText: t('advantage.description2'),
  };
  const secondBlocks = t('advantage.secondBlockTexts', { returnObjects: true });
  const thirdBlocks = t('advantage.thirdBlockTexts', { returnObjects: true });

  return (
    <div className='advantage-wrapper'>
      <div className='left-sidebar-background-img'></div>

      <div className='advantage-content'>
        <h2
          className='block-title-h2'
          dangerouslySetInnerHTML={{ __html: t('advantage.title') }}
        ></h2>
        <FirstAdvantageBlock
          handleScrollToExchange={handleScrollToExchange}
          text={firstBlockText}
        />
        <h2
          className='block-title-h2'
          dangerouslySetInnerHTML={{ __html: t('advantage.title2') }}
        ></h2>
        <SecondAdvantageBlock blocks={secondBlocks} />
        <h2
          className='block-title-h2'
          dangerouslySetInnerHTML={{ __html: t('advantage.title3') }}
        ></h2>
        <ThirdBlock blocks={thirdBlocks} />
      </div>
      <div className='right-sidebar-background-img'></div>
    </div>
  );
};

export default Advantages;

export const CityAdvantages = ({ handleScrollToExchange, cityText }) => {
  const { t } = useTranslation();
  const firstBlocks = fillTemplate(
    t(`usdtTemplates.${cityText.template}.advantages.firstAdvantageBlock`, {
      returnObjects: true,
    }),
    cityText,
  );
  const secondBlocks = fillTemplate(
    t(`usdtTemplates.${cityText.template}.advantages.secondAdvantageBlock`, {
      returnObjects: true,
    }),
    cityText,
  );
  const thirdBlocks = fillTemplate(
    t(`usdtTemplates.${cityText.template}.advantages.thirdAdvantageBlock`, {
      returnObjects: true,
    }),
    cityText,
  );

  return (
    <div className='advantage-wrapper'>
      <div className='left-sidebar-background-img'></div>

      <div className='advantage-content'>
        <h2
          className='block-title-h2'
          dangerouslySetInnerHTML={{
            __html: fillTemplate(
              t(`usdtTemplates.${cityText.template}.advantages.title`),
              cityText,
            ),
          }}
        ></h2>
        <FirstAdvantageBlock
          handleScrollToExchange={handleScrollToExchange}
          text={firstBlocks}
        />
        <h2
          className='block-title-h2'
          dangerouslySetInnerHTML={{
            __html: fillTemplate(
              t(`usdtTemplates.${cityText.template}.advantages.title2`),
              cityText,
            ),
          }}
        ></h2>
        <SecondAdvantageBlock blocks={secondBlocks} />
        <h2
          className='block-title-h2'
          dangerouslySetInnerHTML={{
            __html: fillTemplate(
              t(`usdtTemplates.${cityText.template}.advantages.title3`),
              cityText,
            ), }}
        ></h2>
        <ThirdBlock blocks={thirdBlocks} />
      </div>
      <div className='right-sidebar-background-img'></div>
    </div>
  );
};
