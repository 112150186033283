import axios from 'axios';
import i18next from 'i18next';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

// Общая функция для отправки данных
const sendData = async (path, data) => {
    try {
        const response = await axios.post(`${API_ENDPOINT}${path}/`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': i18next.language
            },
            withCredentials: false,
        });

        return response;
    } catch (error) {
        return false;
    }
};

// Функция для отправки обратной связи
const feedbackSubmit = async (data) => {
    const feedbackData = {
        tg_or_mail: data.email,
        name: data.name,
        title: data.subject,
        description: data.message,
    };
    return sendData('feedback', feedbackData);
};

// Функция для отправки nозыва
const reviewSubmit = async (data) => {
    const reviewData = {
        name: data.name,
        email: data.email,
        stars: data.stars,
        description: data.message
    }
    return sendData('review', reviewData);
};


const exchangeSubmit = async (data) => {
    const exchangeData = {
        "user": data.user,
        "client": data.client,
        "city": data.city_id,
        "from_currency": data.from_currency_id,
        "to_currency": data.to_currency_id,
        "give_quantity": parseFloat(data.give_quantity),
        "exchange_rate": parseFloat(data.exchange_rate).toFixed(8),
        "get_quantity": parseFloat(data.get_quantity),
        "exchange_status": 1,
        "rates": data.rates
    };
    if (data.card_number || data.wallet_address ) {
        exchangeData.requisite_details = {
            "card_number": data.card_number,
            "wallet_address": data.wallet_address
        };
    }
    return sendData('exchange_create', exchangeData);
}


const clientSubmit = async (data) => {

    const clientData = {
        first_name: data.first_name,
        email: data.email,
        tg_user_name: data.tg_user_name,
        user: data.user
      };

    return sendData('client', clientData);
}

const incrementReferralLinkClick = async (data) => {
    return sendData('increment-referral-click', data);
}

export { feedbackSubmit, reviewSubmit, exchangeSubmit,  clientSubmit, incrementReferralLinkClick};

