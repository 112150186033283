import React from 'react';
import formatDate from '../utils/formatDate';
import { useTranslation } from 'react-i18next';
import './styles/lastExchanges.css'; // Убедитесь, что путь к CSS-файлу верный

const LastExchanges = ({ exchanges }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <div className='last-exchanges-wrapper'>
      <div className='last-exchanges'>
        <h3>{t('lastExchanges.lastExchanges')}</h3>
        <div className='divider'></div>
        {exchanges.map((exchange, index) => (
          <div key={exchange.id} className='exchange-item'>
            <div className='exchange-amount'>
              <span className='amount'>{exchange.give_quantity}</span>
              <div className='currency'>{exchange.from_currency}</div>
            </div>
            <div className='exchange-info'>
              <div className='exchange-date'>
                {formatDate(exchange.created_at, currentLanguage)}
              </div>
            </div>
            <div className='exchange-amount received-amount'>
              <span className='amount'>{exchange.get_quantity}</span>
              <div className='currency'>{exchange.to_currency}</div>
            </div>
            {index !== exchanges.length - 1}
            <hr />
          </div>
        ))}
      </div>
      <div className='button-container'>
        <a
          href='tg://resolve?domain=Dimmar_exchange_Turkey'
          target='_blank'
          rel='noopener noreferrer'
          className='subscribe-button'
        >
          {t('lastExchanges.subscribe')}
          </a>
      </div>
    </div>
  );
};

export default LastExchanges;
