import { useTranslation } from 'react-i18next';
import exnodeLogo from './../../img/exnode-logo.png';
import './styles/advantages.css';
import './styles/partners.css';

const Partners = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const bestChangeLink =
    currentLanguage === 'ru' ? 'https://www.bestchange.ru/' : 'https://www.bestchange.com/';

  return (
    <div className='partners-wrapper'>
      <div className='partners-title-container'>
        <h2
          className='block-title-h2'
          dangerouslySetInnerHTML={{ __html: t('main.partnersTitle') }}
        ></h2>
        <p>{t('main.partnersDesc')}</p>
      </div>

      <div className='partners-container'>
        <div>
          <a target='_blank' rel='noreferrer' href={bestChangeLink}>
            <img
              src='https://www.bestchange.ru/bestchange.gif'
              title='Обменные пункты'
              alt='BestChange - Мониторинг обменников'
              width='50'
              height='100'
            />
          </a>
        </div>
        <div>
          <a
            target='_blank'
            rel='noreferrer'
            href='https://exnode.ru/exchangers/exchanger-2398620'
          >
            <img
              src={exnodeLogo}
              alt='Мониторинг обменников Exnode'
              title='Exnode - Мониторинг обменников'
              width='50'
              height='100'
            />
          </a>
        </div>
        <div>
          <a
            href='https://kurs.expert/ru/obmennik/dimmarex-com/feedbacks.html'
            target='_blank'
            rel='noreferrer'
            title='Обмен Биткоин, Киви, Яндекс, AdvCash'
          >
            <img
              src='https://kurs.expert/i/btn.cb.png'
              alt='Мониторинг обменников Kurs Expert'
              width='50'
              height='100'
            />
          </a>
        </div>
        <div>
          <a href='https://e-mon.ru/' target='_blank' rel='noreferrer'>
            <img
              src='https://e-mon.ru/b88x31.png'
              alt='Выгодный курс обмена валют'
              title='Выгодный курс обмена валют'
              border='0'
              width='50'
              height='100'
            />
          </a>
        </div>
        <div>
          <a
            href='https://multirates.org/exchangers/dimmarex-com'
            target='_blank'
            rel='noreferrer'
          >
            <img
              src='https://multirates.org/banner8831.png'
              alt='Мониторинг обменников Multirates'
              title='Мониторинг обменников Multirates'
              border='0'
              width='50'
              height='100'
            />
          </a>
        </div>
        <div>
          <a
            target='_blank'
            rel='noreferrer'
            href='https://change.pro/exchangers/dimmar-exchange'
          >
            <img
              src='https://change.pro/media/images/logos/logo-dark.svg'
              alt='Мониторинг обменников Change PRO'
              title='Change PRO - Мониторинг обменников'
              width='50'
              height='100'
            />
          </a>
        </div>
        <div>
          <a
            href='https://bits.media/exchanger/dimmar-exchange'
            target='_blank'
            rel='noreferrer'
            title='Мониторинг обменников BestExchangers.ru'
          >
            <img
              src='https://bits.media/local/templates/bits.media/images/bits3.png'
              border='0'
              alt='BestExchangers.ru'
              width='50'
              height='100'
            />{' '}
          </a>
        </div>
        <div
        // class='trustpilot-widget'
        // data-locale='en-US'
        // data-template-id='56278e9abfbbba0bdcd568bc'
        // data-businessunit-id='661c698e0fd37bafbe45fe4e'
        // data-style-height='52px'
        // data-style-width='20%'
        >
          <a
            href='https://www.trustpilot.com/review/dimmarex.com'
            target='_blank'
            rel='noreferrer'
          >
            Trustpilot
          </a>
        </div>
      </div>
    </div>
  );
};

export default Partners;
